<template>
    <div class="iframe-container">
        <a class="btn" @click="toIndex"></a>
        <iframe ref="myIframe" src="https://taiyitong.hxcx.com.cn/manage-health/" width="100%" :height="iframeHeight" frameborder="0" scrolling="auto" id="iframename" name="iframename"></iframe>
    </div>
</template>
<script>
export default {
    data() {
        return {
            iframeHeight: 0
        }
    },
    mounted() {
        // this.iframeHeight = document.body.clientHeight;
        // this.iframeHeight = window.innerHeight;
        this.iframeHeight = window.screen.height - 44
    },
    methods: {
        toIndex() {
            this.$router.push('/index')
        }
    }
}
</script>
<style lang="scss" scoped>
.iframe-container {
    height: 100vh;
}

.btn {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
}
</style>